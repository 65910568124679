<template>
  <div
    id="app"
    class="h-100"
    :class="`${[skinClasses]} ${to_dark_mode}`"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />

    <modal-brandme :key="change_modal" :show_modal="modal_responsive" :size="'lg'" @hidden="closeModal" v-if="modal_responsive && is_small_screen">
      <template #body="">
        <div class="d-flex flex-column align-items-center justify-content-center p-2 text-center">
          <b-img :src="img" class="size-img-modal-phone-info"/>
          <h2 class="mt-2">{{ $t('creator.titleResponsive') }}</h2>
          <span class="mt-2">{{ $t('creator.msjModalResponsive') }}</span>
          <b-button class="blue-button mt-2" variant="blue-button" @click="changeModal()">{{ $t('creator.btnModalResponsive') }}</b-button>
        </div>
      </template>
    </modal-brandme>
  </div>
</template>

<script>
import {
  BButton,
  BImg,
} from 'bootstrap-vue';
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import utils from '@/libs/utils';
import { useWindowSize, useCssVar } from '@vueuse/core'
import { eventClosed } from '@/libs/utils/hubspot';
import abilities from '@/libs/acl/abilities';
import store from '@/store'
import { getUserData } from '@/libs/utils/user';
import service_proposal from '@/services/proposals';

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
    BButton,
    modalBrandme: () => import('@/views/components/modal/ModalBrandme.vue'),
    BImg,
  },
  data() {
    return {
      is_small_screen: window.innerWidth < 576,
      change_modal: false,
      modal_responsive: false,
      img: require('@/assets/images/pages/not-authorized.svg'),
      imgOG: "https://brandme.la/wp-content/uploads/2019/02/Land_Facebook.png",
      to_dark_mode: utils.getSkinMode().value === 'dark' ? 'to-dark-mode' : '',
      is_realty: utils.getUserData() && utils.getUserData().main_group && utils.getUserData().main_group.name === 'realty' ? 'realty' : '',
      interval_reconnect: null,
      attempts: 0
    }
  },
  metaInfo() {
    return {
      title: "BrandMe ∣ Pioneros, Expertos, Líderes en Influencer Marketing",
      meta: [
        { vmid: "og:title", property: 'og:title', content: "BrandMe ∣ Pioneros, Expertos, Líderes en Influencer Marketing" },
        { vmid: "og:image", property: 'og:image', content: this.imgOG },
        { vmid: "og:description", property: 'og:description', content: "BrandMe crea herramientas y tecnología que marcan tendencia en el ecosistema de Influencer Marketing para una correcta implementación en campañas." },
        { vmid: "twitter:card", name: 'twitter:card', content: "summary_large_image" },
        { vmid: "twitter:title", name: 'twitter:title', content: "BrandMe ∣ Pioneros, Expertos, Líderes en Influencer Marketing" },
        { vmid: "twitter:description", name: 'twitter:description', content: "BrandMe crea herramientas y tecnología que marcan tendencia en el ecosistema de Influencer Marketing para una correcta implementación en campañas." },
        { vmid: "twitter:image", name: 'twitter:image', content: "https://brandme.la/wp-content/uploads/2019/02/Land_Twitter.png" },
        { vmid: "twitter:site", name: 'twitter:site', content: "@brandme" },
        { vmid: "twitter:label1", name: 'twitter:label1', content: "Tiempo de lectura" },
        { vmid: "twitter:data1", name: 'twitter:data1', content: "12 minutos" }
      ]
    };
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  created() {
    this.$root.$on('open_chat_hubspot', this.openChat)

    this.$root.$on('connect_ws', () => {
      this.connect();
    });
    this.$root.$on('modal_responsive', () => {
      this.modal_responsive = true
      this.change_modal = !this.change_modal
    });

    const user = utils.getUserData()

    if (user) {
      this.connect();
      this.getStatusPlan();
    }
  },
  methods: {
    openChat() {
      const js_hubspot = document.getElementById('hs-script-loader');
      if (js_hubspot) {
        const status = window.HubSpotConversations.widget.status();
        if (!status.loaded) {
          window.HubSpotConversations.widget.load({ widgetOpen: true });
          window.HubSpotConversations.on('widgetClosed', eventClosed);
        } else window.HubSpotConversations.widget.open();
      } else {
        const script_chat = document.createElement('script');
        script_chat.src = "//js.hs-scripts.com/2325482.js";
        script_chat.id = 'hs-script-loader';
        document.head.append(script_chat);

        setTimeout(() => {
          window.HubSpotConversations.on('conversationClosed', () => {
            window.HubSpotConversations.on('widgetClosed', eventClosed);
          });

          window.HubSpotConversations.on('unreadConversationCountChanged', event => {
            if (event.unreadCount > 0) window.HubSpotConversations.off('widgetClosed', eventClosed);
          });

          window.HubSpotConversations.on('conversationStarted', () => {
            window.HubSpotConversations.off('widgetClosed', eventClosed);
          });
          window.HubSpotConversations.widget.remove();

          setTimeout(() => {
            window.HubSpotConversations.widget.load({ widgetOpen: true });
            window.HubSpotConversations.on('widgetClosed', eventClosed);
          }, 1);
        }, 1500);
      }
    },
    closeModal() {
      this.change_modal = !this.change_modal
      this.modal_responsive = false
    },
    changeModal() {
      this.change_modal = !this.change_modal
      this.modal_responsive = false
    },
    setDataPlan(data) {
      const user = getUserData();
      for (const key in data) {
        user.current_plan[key] = data[key]
      }
      localStorage.setItem('userData', JSON.stringify(user))
    },
    getStatusPlan() {
      service_proposal.statusPlan().then((response) => {
        store.commit('main_store/SET_INCOMPLETE_PAYMENT', response.incomplete)
        if (response.status === 'trialing') store.commit('main_store/SET_TRIAL_PERIOD', {trial: true, current_period_end: response.current_period_end})
      })
    },
    connect() {
      const user = utils.getUserData()
      const url_ws = `${process.env.VUE_APP_WS_URL}/ws/socket-server/${user.id}/`;

      const socket = new WebSocket(url_ws);
      socket.onopen = () => {
        console.log('Connected!!!');
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type) {
          if (data.type === 'chat') {
            this.$root.$emit('add_push', data)
            
            if (this.$route.name === 'chat-proposal') {
              const params = this.$route.params
              if (Object.keys(params) === 0 || ('chat_uuid' in params && params.chat_uuid !== undefined)) this.$root.$emit('new_message', data)
            }
          } else if (data.type === 'chat_viewed') {
            this.$root.$emit('chat_viewed', data)
          } else if (data.type === 'files_uploaded') {
            this.$root.$emit('files_uploaded', data)
          } else if (data.type === 'notification') {
            this.$root.$emit('add_push', data)
          } else if (data.type === 'donwload_examples') {
            this.$root.$emit('donwload_examples', data)
          } else if (data.type === 'permissions_changes') {
            this.readAbility = abilities.getAbilitie(data.body.detail.user)
            utils.setPermissions(data.body.detail.user);
            this.$ability.update(this.readAbility)
            user.ability = this.readAbility
          } else if (data.type === 'images_created' || data.type === 'variant_images_created') {
            this.$root.$emit('images_created_ws_or_variant', data)
          } else if (data.type === 'chatgpt_response') {
            this.$root.$emit('chatgpt_response_ws', data)
          } else if (data.type === 'video_talk_created') {
            this.$root.$emit('video_talk_created_ws', data)
          } else if (data.type === 'title_proposal' || data.type === 'description_created') {
            this.$root.$emit('proposals_ws_ia', data)
          } else if (data.type === 'chat_active') {
            this.$root.$emit('chat_active', data)
          } else if (data.type === 'proposal_updated') {
            this.$root.$emit('proposal_updated_ws', data)
          } else if (data.type === 'social_text_response') {
            this.$root.$emit('social_text_response', data)
          } else if (data.type === 'change_plan') {
            this.setDataPlan(data.body)
          }
        }
      };

      socket.onclose = () => {
        this.attempts += 1;
        if (this.interval_reconnect) clearInterval(this.interval_reconnect)
        if (this.attempts < 120) {
          this.interval_reconnect = setInterval(() => {
            this.connect();
          }, 1750);
        }
      };

      socket.onerror = () => {
        this.attempts += 1;
        if (this.interval_reconnect) clearInterval(this.interval_reconnect)
        if (this.attempts < 120) {
          this.interval_reconnect = setInterval(() => {
            this.connect();
          }, 1750);
        }
      };
    },
  },

  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
    watch(utils.getSkinMode(), value => {
      if (value === 'dark') this.to_dark_mode = 'to-dark-mode';
      else this.to_dark_mode = '';
    });
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>

<style>
@import 'assets/css/style.css';
@import 'assets/css/flag.css';
@import 'assets/css/iconProfile.css';
@import 'assets/css/other_network.css';
@import 'assets/css/search.css';
</style>
<style>
.size-img-modal-phone-info  {
  width: 100% !important;
  height: 20vh !important;
  object-fit: contain !important;
}
</style>